import React from 'react'

import { Box, Grid } from '@material-ui/core'

import ProductGridCard from '~/components/cards/ProductCard/ProductGridCard'
import AdsSlot from '~/components/shared/AdSlot'
import Section from '~/components/shared/Section'

import Paths from '~/utils/paths'

import { Listing } from '~/models/Listing/interface'

import { useRecentlyAddedSectionStyles } from './homages.styles'

const placeholder = Array.from(new Array(4))

const RecentlyAddedSection: React.FC<{
  data: Listing[]
  loading?: boolean
  isReduce?: boolean
}> = ({ data, loading, isReduce }) => {
  const classes = useRecentlyAddedSectionStyles()

  const newData = data?.length ? data : placeholder

  return (
    <Section
      bgcolor="bg.secondary"
      displayLink
      linkHref={Paths.search.buildSearch({ sorting: 'newest' })}
      title="Recently Added"
      empty={!newData?.length}
      unmountOnEmpty
      loading={loading}
    >
      <Grid container spacing={2}>
        {newData?.slice(0, !isReduce ? 4 : 3)?.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={!isReduce ? 3 : 4}
              key={item?.id || index}
              className={classes.card}
            >
              <ProductGridCard
                data={item}
                fullHeight
                loading={!data?.length}
                // showDealerOnHover={!isMobile}
              />
            </Grid>
          )
        })}
      </Grid>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mt={9}>
        <AdsSlot
          slotId="gam_banner_pos2"
          sizes={[[728, 90]]}
          sizeMapping={[
            {
              viewport: [1280, 0],
              sizes: [
                [728, 90],
                [970, 90]
              ]
            },
            { viewport: [895, 0], sizes: [[728, 90], 'fluid'] },
            {
              viewport: [0, 0],
              sizes: [[300, 250], [320, 100], [320, 50], [300, 50], 'fluid']
            }
          ]}
          targetingArguments={{ pos: '2', native: 'infeed' }}
        />
      </Box>
    </Section>
  )
}

export default RecentlyAddedSection
